import React from 'react';
import PropTypes from 'prop-types';
// import { v4 as uuid } from 'uuid';
import {
    Grid,
} from '@material-ui/core';

import ButtonProgress from '../../../commons/ButtonProgress';
import SelectSearch from '../../../commons/ecal/selectsearch';
import DatePicker from '../../../commons/ecal/datepicker';

import { formatDate } from '../../../commons/util/date';
import { prepareSearchInput } from '../../../commons/util/utilities';
import { REGEX_VALUES } from '../../../utils/constants';

import { useStyles } from './styles';

function Controls (props) {
    const {
        schedules,
        input,
        updateInput,
        updateScheduleSelection,

        generateData,
        generateReport,
        isLoading,
        isLoadingReport,
        setIsLoading,
        setIsLoadingReport,
        reportDisabled,
        onSearchSchedule,
        showProgress,
    } = props;

    const classes = useStyles();

    return (
        <Grid
        className={classes.controlsBase}
        >
            <Grid
                className={classes.controls}
            >
                <Grid className={classes.schedulesFilter}>
                    <SelectSearch
                        label='Schedule'
                        placeHolder='All Schedules'
                        helperText='Search and select schedule'
                        dataProvider={schedules}
                        selected={input.selected}
                        onSelect={(data) => {
                            console.log('on select: ', data);
                            updateScheduleSelection(data);
                        }}
                        isRemoteSearch={true}
                        onRemoteSearch={(search) => {
                            search = prepareSearchInput(search, REGEX_VALUES.SEARCH_INPUT);
                            onSearchSchedule(search);
                        }}
                        showProgress={showProgress}
                    />
                </Grid>

                <DatePicker
                    placeHolder='Select date range'
                    helperText='Please select date'
                    onDateUpdate={(dateData) => {
                        updateInput({
                            ...input,
                            startDate: formatDate(dateData.start),
                            dateStartDate: dateData.start,
                            endDate: formatDate(dateData.end),
                            dateEndDate: dateData.end,
                        });
                    }}
                />

            </Grid>

            <Grid
                container
                className={classes.buttonsControl}
                >
                    <Grid
                         className={classes.buttonSpace}
                        item>
                        <ButtonProgress
                            disabled={reportDisabled}
                            showProgress={isLoadingReport}
                            label="Generate Report (.CSV)"
                            onClick={(e) => {
                                setIsLoadingReport(true);
                                generateReport();
                            }}
                        />
                    </Grid>
                <Grid
                    item
                    >
                    <ButtonProgress
                        showProgress={isLoading}
                        label="Generate Preview"
                        onClick={(e) => {
                            setIsLoading(true);
                            generateData();
                        }}
                    />
                </Grid>
            </Grid>

        </Grid>);
}

Controls.propTypes = {
    schedules: PropTypes.array,
    input: PropTypes.object,
    updateInput: PropTypes.func,

    isLoading: PropTypes.bool,
    isLoadingReport: PropTypes.bool,
    reportDisabled: PropTypes.bool,

    generateData: PropTypes.func,
    generateReport: PropTypes.func,
    setIsLoading: PropTypes.func,
    setIsLoadingReport: PropTypes.func,
    onSearchSchedule: PropTypes.func,
};

Controls.defaultProps = {
    schedules: [],
    input: {},
    updateInput: () => {},
    isLoading: false,
    isLoadingReport: false,
    setIsLoading: () => {},
    setIsLoadingReport: () => {},
    generateData: () => {},
    generateReport: () => {},
    reportDisabled: false,
    onSearchSchedule: () => {},
};

export default Controls;
