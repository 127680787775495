import { useState } from 'react';

function cleanFromDuplicates (source, field = 'value') {
    const idHash = {};
    const newSource = [];
    for (let i = 0; i < source.length; i++) {
        const item = source[i];

        if (item?.id) {
            item.value = item.id;
            item.catName = item.categoryNames;
            item.label = item.name;
        }

        if (item && !idHash[item[field]]) {
            newSource.push(withCategoryName(item));
            idHash[item[field]] = true;
        }
    }
    return newSource;
}

/* Append category name in the end of schedule name when there are more than 3 categories */
function withCategoryName (item) {
    const newItem = { ...item };

    if (!item.catName) {
        return newItem;
    }

    if (item.catName[0] === 'Uncategorized') {
        return newItem;
    }

    if (item.catName.length <= 3 && item.catName.length > 0) {
        newItem.label = `${item.label} (${item.catName.join(', ')})`;
    }

    if (item.catName.length > 3) {
        const categoryList = [];
        const items = item.catName.slice(0, 3);
        categoryList.push(...items);
        const itemsRest = item.catName.slice(3, item.catName.length);
        newItem.catNameJoinedLabel = items.join(', ');
        newItem.catNameRestJoinedLabel = itemsRest.join(', ');
        newItem.label = `${item.label} (${newItem.catNameJoinedLabel} (+${itemsRest.length}))`;
    }

    return newItem;
}

function useRemoteSearch (props = {}) {
    const {
        preselectedProvider = [],
        isDisplaySetting,
    } = props;

    const [selected, updateSelected] = useState([]);
    const [preselectedDataProvider, setPreselected] = useState(preselectedProvider);
    const [dataProvider, setDataProvider] = useState([]);

    function getSelectedItems () {
        if (selected.length <= 0 || dataProvider.length <= 0) {
            return [];
        }

        const selectedItems = selected.map((value) => {
            for (let i = 0; i < dataProvider.length; i++) {
                const data = dataProvider[i];
                if (data.id === value) {
                    return data;
                }
            }
        });

        return selectedItems;
    }

    function updateProvider (data) {
        const selectedItems = getSelectedItems();

        let merged = [
            ...preselectedDataProvider,
            ...selectedItems,
            ...data,
        ];

        merged = cleanFromDuplicates(merged);

        // Hide child schedules from the schedules list in import events feature
        if (!isDisplaySetting) {
            merged = merged.filter((schedule) => {
                return !schedule.isChild;
            });
        };

        setDataProvider([...merged]);
    }

    function updatePreselectedProvider (preselected) {
         setPreselected(preselected);
    }

    function setSelected (selection) {
        validatePreselected(selection);
        updateSelected(selection);
    }

    function validatePreselected (selection) {
        const preselection = preselectedDataProvider;
        for (let i = 0; i < preselection.length; i++) {
            const preselected = preselection[i];
            let removePreselected = true;
            for (let j = 0; j < selection.length; j++) {
                const id = selection[j];
                if (preselected.id === id) {
                    removePreselected = false;
                    break;
                }
            }

            if (removePreselected) {
                preselection.splice(i, 1);
                i--;
            }
        }
        setPreselected(preselection);
    }

    return {
        setSelected,
        selected,
        dataProvider,
        updateProvider,
        updatePreselectedProvider,
    };
}

export default useRemoteSearch;
