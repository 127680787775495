// import moment from 'moment-timezone';
import countries from './country_code.json';
// import timezone from './Timezone.json';

/*
 * A utility function to retrieve client country, country code, country iso2.
 */
// export function clientDefaultCountryCode () {
//     const zone = moment.tz.guess();
//     const keys = Object.keys(countries);
//     let country = 'US';

//     if (!zone) {
//         return countries[country];
//     }

//     for (const key of keys) {
//         const zones = moment.tz.zonesForCountry(key);
//         if (zones && zones.includes(zone)) {
//             country = key;
//             break;
//         }
//     }
//     return countries[country];
// }

export function getCountryCodeItem (code) {
    const keys = Object.keys(countries);

    const selected = keys.filter(key => {
        const country = countries[key];
        if (country.code === code) {
            return true;
        }
        return false;
    });
    if (selected) return countries[selected[0]];
    return null;
}

// export function defaultOrSelectedCountryCode (code) {
//     if (code) {
//         return getCountryCodeItem(code);
//     }
//     return clientDefaultCountryCode();
// }

/*
 * A utility function to get client default timezone.
 */
// export function clientDefaultTimezone () {
//     const zone = moment.tz.guess();
//     let filtertedTimezone = timezone.filter((tzItem) => {
//         if (tzItem.label.trim() === zone) {
//             return true;
//         }
//         return false;
//     });

//     if (!filtertedTimezone) {
//         filtertedTimezone = timezone.filter((tzItem) => {
//             if (tzItem.value === 'AU') return true;
//             return false;
//         });
//         return filtertedTimezone[0];
//     }

//     return filtertedTimezone[0];
// }

export function parentURL () {
    const isInIframe = (window.parent !== window);
    let parentUrl = null;
    if (isInIframe) {
        parentUrl = document.referrer;
    }
    return parentUrl;
}

export function debug (...args) {
    if (window.mode === 'debug') {
        console.log('');
        console.log('');
        console.log(...args);
    }
}

/*
 * A utility function to get and make chart data count number to have comma.
 */
export const getCountsWithComma = (data) => {
  return data?.map((data) => {
    data.count = data.count.toLocaleString();
    return data;
  });
};

/*
 * parse data count number to have K for thousand.
 */
export const parseCountData = (count) => {
  let dataCount;
  dataCount = count >= 100000 ? `${parseFloat((count / 1000).toFixed()).toLocaleString('en')}K` : count?.toLocaleString('en');
  dataCount = dataCount !== '0' ? `${dataCount}` : '0';
  return dataCount;
};

/*
 * get total count in array.
 */
export const getTotalCountInArray = (list) => {
  if (Array.isArray(list)) {
    return list.reduce((total, currentVal) => total + currentVal.count, 0);
  }
  return 0;
};

export const moduleNoDataText = (module) => `There are currently no ${module || 'data'}`;

export const getOptinPercentage = (subscriptionCount, optinCount) => {
  if (subscriptionCount !== 0) {
    const value = (optinCount / subscriptionCount * 100).toFixed();
    return value <= 100 ? `${value}%` : '0';
  }
  return 0;
};

export const prepareSearchInput = (search, regexValue) => {
  if (search) {
    search = search.replace(regexValue, '');
  }
  return search.trim();
};
