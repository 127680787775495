import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './styles';
import { v4 as uuid } from 'uuid';

import {
   Close,
   ExpandMore,
   ExpandLess,
} from '@material-ui/icons';

import PopMenu from './popmenu';
import useSelectSearch from './useSelectSearch';
import { REGEX_VALUES } from '../../../utils/constants';
import { prepareSearchInput } from '../../../commons/util/utilities';

function SelectSearch (props) {
    const {
        selected: selectedSource,
        helperText,
        placeHolder,
        dataProvider: dataSource,
        onSelect,
        showLabelAlways,
        autoClose,
        isRemoteSearch,
        onRemoteSearch,
        dataIntercomTarget,
    } = props;

    const {
        baseRef,
        editRef,

        menuAnchor,
        menuOpen,
        menuWidth,
        setMenuOpen,

        handleDelete,
        handleSelect,
        handleSearch,
        dataProvider,
        selected,
    } = useSelectSearch({
        dataProvider: dataSource,
        selected: selectedSource,
        mode: 'local',
        onSelect,
        isRemoteSearch,
        onRemoteSearch,
    });

    const classes = useStyles({
        placeHolder: selected && selected.length > 0 ? '' : placeHolder,
    });

    function getValueLabel (value) {
        const result = dataSource.filter((data) => (data.value === value));
        if (!showLabelAlways && (!result || result.length <= 0)) {
            return '';
        }
        return result[0].label;
    }

    function renderValues () {
        return selected.map((value) => {
            return (
            <span
                className={classes.inSpan} key={uuid()}
                >
                    <span className={classes.truncate}>{ getValueLabel(value) }</span>
                    <Close
                        className={classes.spanIcon}
                        fontSize={'inherit'}
                        onClick={() => {
                            handleDelete(value);
                        }}
                />
            </span>);
        });
    }

    function renderExpand () {
        if (menuOpen) {
            return <ExpandLess
                ecal-data-id={'select-less'}
                className={classes.expandIcon}
                fontSize={'small'}
                onClick={() => {
                    setMenuOpen(false);
                }}
            />;
        }
        return <ExpandMore
            ecal-data-id={'select-more'}
            className={classes.expandIcon}
            fontSize={'small'}
            onClick={() => {
                console.log('hide menu');
                setMenuOpen(true);
            }}
        />;
    }

    return (
        <React.Fragment>
            <Grid className={classes.componentBase}>
                <Grid
                    data-intercom-target={dataIntercomTarget}
                    className={classes.root}
                    ref={baseRef}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (editRef.current) {
                            editRef.current.focus();
                        }
                    }}
                >
                    <div
                        className={classes.base}
                    >
                        {renderValues()}
                        <div
                            ref={editRef}
                            contentEditable
                            className={classes.editable}
                            onInput={(e) => {
                                let search = e.target.innerText;
                                search = prepareSearchInput(search, REGEX_VALUES.SEARCH_INPUT);
                                handleSearch(search);
                                e.stopPropagation();
                            }}
                            placeholder={placeHolder}
                        >

                        </div>
                    </div>
                    {renderExpand()}
                </Grid>
                <Grid
                    className={classes.helperText}
                >
                    {helperText}
                </Grid>
            </Grid>
                <PopMenu
                    anchorEl={menuAnchor}
                    open={menuOpen}
                    width={menuWidth}
                    dataProvider={dataProvider}
                    selected={selected}
                    onClose={() => setMenuOpen(false)}
                    onSelect={(data) => {
                        handleSelect(data);
                        if (autoClose) {
                            setMenuOpen(false);
                        }
                    }}

                    onDelete={(value) => handleDelete(value)}
                />
        </React.Fragment>

    );
}

SelectSearch.propTypes = {
    showLabelAlways: PropTypes.bool,
    autoClose: PropTypes.bool,
      helperText: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.array,
      dataProvider: PropTypes.array,
      onSelect: PropTypes.func,
      placeHolder: PropTypes.string,
      dataIntercomTarget: PropTypes.string,
};

SelectSearch.defaultProps = {
    helperText: 'Search and select options',
    label: 'Select or Search',
    placeHolder: 'Select or search entry',
    showLabelAlways: false,
    autoClose: true,
    dataProvider: [
        { value: 'feature-set', label: 'Feature Sets' },
        { value: 'multi-select', label: 'Multi Select' },
        { value: 'searchable', label: 'Searchable' },
        { value: 'responsive', label: 'Responsive' },
        { value: 'manual delete', label: 'Manual Deletion' },
    ],
    selected: [],
    onSelect: () => {},
    dataIntercomTarget: '',
};

export default SelectSearch;
